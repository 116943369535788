import React from 'react';
import s from './style.module.css';
import cn from 'classnames';
import scaleLinear from 'd3-scale/src/linear';

const YAxis = ({
	ticks,
	min,
	max,
	rtl,
	includeKeyLine,
	formatTick = (value) => value,
	formatValue = (value) => value
}) => {
	const scale = scaleLinear().domain([min, max]).range([0, 100]);

	const allTicks = ticks.map((tick, i) => {
		const base = typeof tick === 'object' ? tick : { value: tick };
		const { value, text = formatTick } = base;

		const formattedValue = formatValue(value);
		const formattedText = text(formattedValue);

		return { value, text: formattedText, offset: scale(value, i) };
	});

	return (
		<div className={s.block}>
			<div className={cn(s.wrap, { [s.includeKeyLine]: includeKeyLine })}>
				{allTicks.map(({ value, text, offset }, i) => (
					<div
						className={s.tick}
						style={{
							transform: `translateY(${100 * i}%)`,
							bottom: `${offset}%`
						}}
					>
						<span className={s.tickText}>{text}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default YAxis;
