import React, { useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useMouse from 'helpers/useMouse';
import Tooltip from 'components/Tooltip';

const useTooltip = (restrictY) => {
	const ref = useRef();
	const [current, setCurrent] = useState(null);
	const [tooltipVisible, setTooltipVisible] = useState(false);

	const debouncedSetCurrent = useDebouncedCallback(setCurrent, 1000 / 60, {
		leading: true
	});

	const mouse = useMouse(ref, {
		enterDelay: 10,
		leaveDelay: 10,
		fps: 60
	});

	const onMouseOver = (bar) => () => {
		setTooltipVisible(true);
		debouncedSetCurrent.callback(bar);
	};

	const onMouseOut = () => () => {
		setTooltipVisible(false);
	};

	// console.log(mouse.x, mouse.y, mouse);

	const TooltipWrapped = (
		<Tooltip
			x={mouse.x}
			y={restrictY ? mouse.elementHeight / 2 : mouse.y}
			data={current}
			isVisible={tooltipVisible}
			containerWidth={mouse.elementWidth}
			containerHeight={mouse.elementHeight}
		/>
	);

	return {
		ref,
		current,
		tooltipVisible,
		onMouseOver,
		onMouseOut,
		Tooltip: TooltipWrapped
	};
};

export default useTooltip;
