import React, { useMemo, useRef, memo } from 'react';
import { forceCollide, forceX, forceY } from 'd3-force';
import scaleLinear from 'd3-scale/src/linear';
import scaleBand from 'd3-scale/src/band';
import s from './style.module.css';
import { IntlProvider, Text } from 'preact-i18n';
import Dots from 'components/Dots';
import useComponentSize from '@rehooks/component-size';
import cn from 'classnames';
import { useChartOnScreen } from 'helpers/useIsVisible';

const BeeSwarm = ({
	data = [],
	min = 0,
	max = 100,
	xDataKey = 'value',
	yDataKey,
	yAlignment,
	dotRadius = 1,
	dotPadding = 0,
	heightRatio = 1,
	includeAxis,
	dotColor,
	copy,
	onMouseOut = () => {},
	onMouseOver = () => {}
}) => {
	const ref = useRef(null);
	// const [wrappedRef, isVisible] = useOnScreen({ threshold: 0.5 }, ref);

	const isVisible = useChartOnScreen();
	const { width, height } = useComponentSize(ref);

	const scaleRadius = useMemo(
		() => scaleLinear().domain([0, 100]).range([0, width]),
		[width]
	);

	const scaleX = useMemo(
		() => scaleLinear().domain([min, max]).range([0, width]),
		[min, max, width]
	);

	const scaleY = useMemo(
		() => scaleBand().domain(yAlignment).range([0, height]),
		[yAlignment, height]
	);

	const startingData = useMemo(
		() =>
			data.map((d) => ({
				...d,
				x: Math.random() > 0.5 ? 0 : width,
				y: scaleY(d[yDataKey]) + scaleY.bandwidth() / 2
			})),
		[data, scaleY]
	);

	const collide = useMemo(
		() =>
			forceCollide()
				.strength(1)
				.radius((d) => scaleRadius(dotRadius) + scaleRadius(dotPadding)),
		[width, scaleRadius, dotRadius, dotPadding]
	);

	const pullTowardsX = useMemo(
		() =>
			forceX()
				.x((d) => scaleX(d[xDataKey]))
				.strength(0.03),
		[xDataKey, scaleX]
	);

	const pullTowardsY = useMemo(
		() =>
			forceY()
				.y((d) => scaleY(d[yDataKey]) + scaleY.bandwidth() / 2)
				.strength(0.03),
		[yDataKey, scaleY]
	);

	const forces = useMemo(
		() => [
			['x', pullTowardsX],
			['y', pullTowardsY],
			['collide', collide]
		],
		[pullTowardsX, pullTowardsY, collide]
	);

	return (
		<IntlProvider definition={copy}>
			<div
				ref={ref}
				className={cn(s.dotsWrap, { [s.isVisible]: isVisible })}
				style={{
					paddingBottom: `${heightRatio * 100}%`
				}}
			>
				{includeAxis &&
					yAlignment.map((key) => (
						<div
							className={s.axis}
							style={{
								bottom: `${height - (scaleY(key) + scaleY.bandwidth() / 2)}px`
							}}
						>
							<div className={s.axisText}>
								<Text id={`${key}.axisLabel`} />
							</div>
						</div>
					))}

				<div className={s.dots}>
					{width && isVisible && (
						<Dots
							items={startingData}
							forces={forces}
							dotRadius={scaleRadius(dotRadius)}
							dotColor={dotColor}
							onMouseOut={onMouseOut}
							onMouseOver={onMouseOver}
						/>
					)}
				</div>
			</div>
		</IntlProvider>
	);
};

export default memo(BeeSwarm);
