import React from 'react';
import s from './style.module.css';

const Dot = ({ node, dotRadius, dotColor, onMouseOut, onMouseOver }) => {
	const { x, y, radius, fill } = node;
	return (
		<div
			className={s.dot}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			style={{
				transform: `translate(${x}px, ${y}px) translate(-50%, -50%)`,
				width: `${(radius || dotRadius) * 2}px`,
				paddingBottom: `${(radius || dotRadius) * 2}px`,
				color: `${fill || dotColor}`
			}}
		/>
	);
};

export default Dot;
