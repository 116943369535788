import React, { memo } from 'react';
import useSimulation from 'helpers/useSimulation';
import Dot from './Dot';

const Dots = ({
	items,
	forces,
	dotRadius,
	dotColor,
	overrideTooltipPosition,
	scaleX,
	scaleY,
	onMouseOver,
	onMouseOut
}) => {
	const nodes = useSimulation(items, forces);

	// const nodes = items;

	return (
		<div>
			{nodes.map((node, i) => (
				<Dot
					key={`${i}-dot`}
					node={node}
					dotRadius={dotRadius}
					dotColor={dotColor}
					overrideTooltipPosition={overrideTooltipPosition}
					onMouseOver={onMouseOver(node)}
					onMouseOut={onMouseOut()}
				/>
			))}
		</div>
	);
};

export default memo(Dots);
