import { useMemo } from 'react';

export default (id, config = {}, data = {}, copy = {}) => {
	const memo = useMemo(() => {
		const configFromPage = window.BW_WHO_COVID_DATA || {};
		const { data, copy } = configFromPage[id] || {};

		return {
			data,
			copy,
			config
		};
	}, [id]);

	return memo;
};
