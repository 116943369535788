import Chart from 'components/Chart';
import StackedBarChart from 'components/StackedBarChart';
import useChartConfig from 'helpers/useChartConfig';

const SDGRecentData = ({ id }) => {
	const { data, copy, config } = useChartConfig('SDGRecentData', {
		colors: {
			recent: '#00A073',
			old: '#5EB0E8',
			noData: '#D4D4D4',
			notRelevant: '#D4D4D4'
		},
		outlined: {
			notRelevant: true
		},
		min: 0,
		max: 194,
		ticks: [0, 194],
		stackOrder: ['recent', 'old', 'noData', 'notRelevant']
	});

	return (
		<Chart id={id} copy={copy}>
			<StackedBarChart data={data} {...config} />
		</Chart>
	);
};

export default SDGRecentData;
