import * as Preact from 'preact';
const { h, render } = Preact;

const interopDefault = (m) => (m && m.default ? m.default : m);

if (process.env.NODE_ENV === 'development') {
	// enable preact devtools
	require('preact/debug');
}

let app = interopDefault(require('preact-cli-entrypoint'));

if (typeof app === 'function') {
	let root = document.getElementById('preact_root');

	let init = () => {
		let app = interopDefault(require('preact-cli-entrypoint'));
		root = render(h(app, {}), document.body, root);
	};

	if (module.hot) module.hot.accept('preact-cli-entrypoint', init);

	init();
}
