import s from './style.module.css';
import cn from 'classnames';
import scaleLinear from 'd3-scale/src/linear';
import { Text, useText } from 'preact-i18n';
import XAxis from 'components/XAxis';
import VerticalGridLines from 'components/VerticalGridLines';
import { useChartOnScreen } from 'helpers/useIsVisible';

const getValueLabel = (value) => {
	const replace = useText('valueLabels').valueLabels;
	if (!replace) {
		return value;
	}

	return replace.replace(/{value}/gi, value);
};

const HorizontalBarChart = ({
	data = {},
	min = 0,
	max = 100,
	xAxis,
	verticalGridLines,
	colors,
	transitionTotal = 1
}) => {
	const isVisible = useChartOnScreen();

	const scale = scaleLinear().domain([min, max]).range([0, 100]);

	const bars = Object.entries(data).map(([key, value]) => ({
		id: key,
		value: getValueLabel(value),
		offset: scale(value)
	}));

	return (
		<div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
			<div className={s.table}>
				<div className={s.caption}>
					<Text id={`chartTitle`} />
				</div>

				<div className={s.bars}>
					{verticalGridLines && (
						<VerticalGridLines
							{...verticalGridLines}
							isVisible={isVisible}
							transitionTotal={transitionTotal}
						/>
					)}

					{bars.map(({ id, value, offset }, i) => (
						<div className={s.row}>
							<div className={s.label}>
								<Text id={`dataLabels.${id}`} />
							</div>
							<div className={s.cell}>
								<span className={s.barWrap}>
									<span
										className={s.bar}
										style={{
											color: colors[id],
											transitionDuration: `${(offset / 100) * 5}s`,
											width: `${offset}%`
										}}
									/>
								</span>
								<span className={s.value}>{value}</span>
							</div>
						</div>
					))}

					<div
						className={s.stretch}
						style={{
							left: '61%',
							width: '9%'
						}}
					>
						<svg
							className={s.leftArrow}
							width="24"
							height="24"
							viewBox="0 0 100 100"
							style={{ overflow: 'visible', transform: 'scaleX(-1)' }}
						>
							<path
								strokeWidth="4"
								d="M100,0 L80,-20"
								stroke="#000"
								fill="transparent"
							/>
							<path
								strokeWidth="4"
								d="M100,0 L80,20"
								stroke="#000"
								fill="transparent"
							/>
						</svg>

						<svg
							className={s.line}
							height="24"
							preserveAspectRatio="none"
							viewBox="0 0 100 100"
							style={{ overflow: 'visible' }}
						>
							<path
								strokeWidth="3"
								d="M100,0 L0,0"
								stroke="#000"
								fill="transparent"
							/>
						</svg>

						<svg
							className={s.rightArrow}
							width="24"
							height="24"
							viewBox="0 0 100 100"
							style={{ overflow: 'visible' }}
						>
							<path
								strokeWidth="4"
								d="M100,0 L80,-20"
								stroke="#000"
								fill="transparent"
							/>
							<path
								strokeWidth="4"
								d="M100,0 L80,20"
								stroke="#000"
								fill="transparent"
							/>
						</svg>
					</div>
				</div>

				{xAxis && (
					<div>
						<XAxis
							{...xAxis}
							insetEnds
							isVisible
							transitionTotal={transitionTotal}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default HorizontalBarChart;
