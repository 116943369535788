import React, { useRef, memo } from 'react';
import cn from 'classnames';
import s from './style.module.css';
import scaleLinear from 'd3-scale/src/linear';
import { histogram } from 'd3-array';
import { area, curveBasis } from 'd3-shape';
import useComponentSize from '@rehooks/component-size';
import { useChartOnScreen } from 'helpers/useIsVisible';

const ViolinChart = ({
	data,
	dataKey = 'recentData',
	min,
	max,
	fill = 'red',
	numberOfBins = 20,
	top = 13,
	heightRatio = 0.2
}) => {
	const ref = useRef();
	const { width, height } = useComponentSize(ref);

	const isVisible = useChartOnScreen();

	const x = scaleLinear().domain([min, max]).range([0, width]);

	const createBins = histogram()
		.value((d) => d[dataKey])
		.domain([0, 100])
		.thresholds(x.ticks(numberOfBins));

	const y = scaleLinear()
		.domain([0, top])
		.range([height / 2, 0]);

	const bins = createBins(data);
	const createArea = area()
		.curve(curveBasis)
		.x((d) => x(d.x0))
		.y0(height / 2)
		.y1((d) => y(d.length));

	return (
		<div
			ref={ref}
			className={cn(s.wrap, { [s.isVisible]: isVisible })}
			style={{
				paddingBottom: `${heightRatio * 100}%`
			}}
		>
			<svg className={s.svg}>
				<path fill={fill} fillOpacity={0.15} d={createArea(bins)} />
				<path
					fill={fill}
					fillOpacity={0.15}
					d={createArea(bins)}
					transform={`scale(1, -1) translate(0 ${-height})`}
				/>
			</svg>
		</div>
	);
};

export default memo(ViolinChart);
