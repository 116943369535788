import React from 'react';
import s from './style.module.css';
import cn from 'classnames';
import scaleLinear from 'd3-scale/src/linear';

const StackedBar = ({
	i = 0,
	min = 0,
	max = 100,
	data = {},
	colors = {},
	outlined = {},
	stackOrder
}) => {
	const scale = scaleLinear().domain([min, max]).range([0, 1]);

	const order = stackOrder || Object.keys(data);

	const stacks = order.map((key) => ({
		key,
		value: scale(data[key]),
		color: colors[key],
		outlined: outlined[key]
	}));

	return (
		<div className={s.barWrap}>
			<div
				className={s.bar}
				style={{
					transitionDelay: `${(1 - stacks[0].value) * 2 + 0.2}s`
				}}
			>
				{stacks.map(({ key, value, color, outlined }, n) => (
					<div
						className={cn(s.stack, { [s.outlined]: outlined })}
						style={{
							color,
							zIndex: 1 + (stacks.length - n),
							flex: `${value} 0 0px`
						}}
					/>
				))}
			</div>
		</div>
	);
};

export default StackedBar;
