import React from 'react';
import s from './style.module.css';
import cn from 'classnames';
import { MarkupText, useText } from 'preact-i18n';

const Inner = ({ data }) => {
	const textLookup = useText(data);
	const fields = {
		...data,
		textLookup
	};

	return (
		<div className={s.tooltip}>
			<div className={s.title}>
				<MarkupText id="tooltipTitle" fields={fields} />
			</div>
			<MarkupText id="tooltip" fields={fields} />
		</div>
	);
};

const Tooltip = ({ x, y, data, isVisible, containerWidth }) => (
	<div
		className={cn(s.tooltipWrap, {
			[s.isVisible]: isVisible,
			[s.onRight]: x > containerWidth / 2
		})}
		style={{
			transform: `translateX(${x}px) translateX(${
				x > containerWidth / 2 ? '-100%' : '0'
			}) translateY(${y}px) translateY(-50%)`
		}}
	>
		<Inner data={data} />
	</div>
);

export default Tooltip;
