import React, { useMemo } from 'react';
import s from './style.module.css';
import cn from 'classnames';
import StackedBar from 'components/StackedBarChart/StackedBar';
import Flow from 'components/Flow';
import YAxis from 'components/YAxis';
import XAxis from 'components/XAxis';
import Legend from 'components/Legend';
import useTooltip from 'helpers/useTooltip';
import { useChartOnScreen } from 'helpers/useIsVisible';

const StackedBarChart = ({
	min,
	max,
	ticks,
	colors = {},
	outlined = {},
	data = {},
	stackOrder
}) => {
	const { ref, Tooltip, onMouseOver, onMouseOut, tooltipVisible } = useTooltip(
		true
	);

	const isVisible = useChartOnScreen();

	const bars = Object.entries(data)
		.map(([key, value]) => value)
		.sort((a, b) => b.order - a.order);

	const keys = useMemo(() => Object.keys(colors), [colors]);

	const wrappedBars = useMemo(
		() => (
			<>
				<YAxis min={min} max={max} ticks={ticks} />
				{bars.map((bar, i) => (
					<div
						key={i}
						className={s.hover}
						onMouseOver={onMouseOver(bar)}
						onMouseOut={onMouseOut()}
					>
						<StackedBar
							min={min}
							max={max}
							data={bar.data}
							colors={colors}
							outlined={outlined}
							stackOrder={stackOrder}
						/>
					</div>
				))}
			</>
		),
		[]
	);

	return (
		<div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
			<Legend keys={keys} colors={colors} outlined={outlined} />

			<div
				ref={ref}
				className={cn(s.hoverWrap, {
					[s.fadeOut]: tooltipVisible
				})}
			>
				{Tooltip}
				<Flow includeAxis>{wrappedBars}</Flow>
			</div>

			<XAxis />
		</div>
	);
};

export default StackedBarChart;
