import React from 'react';
import s from './style.module.css';
import cn from 'classnames';
import scaleLinear from 'd3-scale/src/linear';

const VerticalGridLines = ({
	min,
	max,
	ticks,
	transitionTotal = 2,
	isVisible
}) => {
	const scale = scaleLinear().domain([min, max]).range([0, 100]);

	const allTicks = ticks.map(scale);

	return (
		<div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
			{allTicks.map((offset, i) => (
				<div
					className={s.tick}
					style={{
						transitionDelay: `${(transitionTotal / allTicks.length) * i}s`,
						transform: `translateX(${offset}%)`
					}}
				/>
			))}
		</div>
	);
};

export default VerticalGridLines;
