import React from 'react';
import cn from 'classnames';
import s from './style.module.css';
import { MarkupText } from 'preact-i18n';
import { useChartOnScreen } from 'helpers/useIsVisible';

const Note = ({ x, y, delay, id, restricted, iconPosition = 'tl', icon }) => {
	const isVisible = useChartOnScreen();

	return (
		<div
			className={cn(s.wrap, {
				[s.isVisible]: isVisible,
				[s.restricted]: restricted,
				[s.hasIcon]: icon,
				[s[`icon-${iconPosition}`]]: iconPosition
			})}
			style={{
				left: `${x}%`,
				top: `${y}%`
			}}
		>
			<div className={s.text}>
				<MarkupText id={`notes.${id}`} />
			</div>

			<div className={s.svg}>{icon}</div>
		</div>
	);
};

export default Note;
