import Chart from 'components/Chart';
import HorizontalBarChart from 'components/HorizontalBarChart';
import Note from 'components/Note';
import useChartConfig from 'helpers/useChartConfig';

const formatTick = (tick) => `${tick}${tick === 0 || tick === 100 ? '%' : ''}`;

const GenderDataGap = ({ id }) => {
	const { data, copy, config } = useChartConfig('genderDataGap', {
		min: 0,
		max: 100,
		ticks: [0, 20, 40, 60, 80, 100],
		colors: {
			bothSex: '#00A173',
			sexSpecific: '#00A173'
		}
	});

	return (
		<Chart id={id} copy={copy}>
			<div
				style={{
					position: 'relative'
				}}
			>
				<Note id="gap" x={72} y={48.5} restricted />

				<HorizontalBarChart
					data={data}
					verticalGridLines={config}
					{...config}
					xAxis={{
						...config,
						formatTick
					}}
				/>
			</div>
		</Chart>
	);
};

export default GenderDataGap;
