import React from 'react';
// import cn from 'classnames';
import s from './style.module.css';

const Spacer = ({ size = 0.1 }) => (
	<div
		className={s.spacer}
		style={{
			marginBottom: `${size * 100}%`
		}}
	/>
);

export default Spacer;
