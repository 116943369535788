import './style';
import SDGRecentData from 'charts/SDGRecentData';
import CountriesRecentData from 'charts/CountriesRecentData';
import GenderDataGap from 'charts/GenderDataGap';

const App = () => (
	<>
		<SDGRecentData id="sdg-recent-data" />
		<CountriesRecentData id="countries-recent-data" />
		<GenderDataGap id="gender-data-gap" />
	</>
);

export default App;
