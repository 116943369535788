import { IntlProvider, Text } from 'preact-i18n';
import s from './style.module.css';
import { createPortal } from 'preact-compat';
import { useOnScreen, InViewProvider } from 'helpers/useIsVisible';

const Chart = ({ id, copy = {}, children, ...rest }) => {
	const [ref, inView] = useOnScreen({ threshold: 0.5 });

	const chart = (
		<IntlProvider definition={copy}>
			<InViewProvider value={inView}>
				<div className={s.wrap} {...rest}>
					<h2>
						<Text id="title" />
					</h2>

					<div className={s.chart} ref={ref}>
						{children}
					</div>

					{copy.note && (
						<small>
							<Text id="note" />
						</small>
					)}
				</div>
				<></>
			</InViewProvider>
		</IntlProvider>
	);

	if (!id) {
		return chart;
	}

	if (typeof window === 'undefined') {
		return null;
	}

	const elem = document.getElementById(id);

	if (!elem) {
		return null;
	}

	return createPortal(chart, document.getElementById(id));
};

export default Chart;
