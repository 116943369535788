import React from 'react';
import s from './style.module.css';
import cn from 'classnames';
import { Text } from 'preact-i18n';
import scaleLinear from 'd3-scale/src/linear';

const XAxis = ({
	ticks = [],
	min = 0,
	max = 100,
	rtl,
	includeKeyLine,
	formatTick = (value) => value,
	formatValue = (value) => value,
	isVisible,
	transitionTotal = 2,
	insetEnds
}) => {
	const scale = scaleLinear().domain([min, max]).range([0, 100]);

	const allTicks = ticks.map((tick, i) => {
		const base = typeof tick === 'object' ? tick : { value: tick };
		const { value, text = formatTick } = base;

		const formattedValue = formatValue(value);
		const formattedText = text(formattedValue);

		return { value, text: formattedText, offset: scale(value, i) };
	});

	return (
		<div
			className={cn(s.all, {
				[s.isVisible]: isVisible,
				[s.insetEnds]: insetEnds
			})}
		>
			{allTicks.length > 0 && (
				<div className={cn(s.wrap, { [s.includeKeyLine]: includeKeyLine })}>
					{allTicks.map(({ value, text, offset }, i) => (
						<div
							className={s.tick}
							style={{
								transitionDelay: `${(transitionTotal / allTicks.length) * i}s`,
								transform: `translateX(${offset}%)`
							}}
						>
							<span className={s.tickText}>{text}</span>
						</div>
					))}
				</div>
			)}

			<p className={s.label}>
				<Text id="xAxisLabel" />
			</p>
		</div>
	);
};

export default XAxis;
