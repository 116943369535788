import { createContext, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

const InViewContext = createContext(false);

export const InViewProvider = InViewContext.Provider;

export const useOnScreen = (options, exisitingRef) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.4,
		...options
	});

	return [ref, inView];
};

export const useChartOnScreen = () => useContext(InViewContext);
