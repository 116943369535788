import React, { memo } from 'react';
import cn from 'classnames';
import s from './style.module.css';
import { Text } from 'preact-i18n';

const Legend = ({ keys, colors = {}, outlined = {} }) => {
	const boxes = keys.map((key) => ({
		key,
		color: colors[key],
		outlined: outlined[key]
	}));

	return (
		<div className={s.wrap}>
			<p>
				<Text id="legendTitle" />
			</p>

			<div className={s.items}>
				{boxes.map(({ key, text, color, outlined }) => (
					<div className={s.item}>
						<span
							className={cn(s.box, { [s.outlined]: outlined })}
							style={{
								color
							}}
						/>
						<Text id={key} />
					</div>
				))}
			</div>
		</div>
	);
};

export default memo(Legend);
