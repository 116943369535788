import { useMemo } from 'react';
import Chart from 'components/Chart';
import BeeSwarm from 'components/BeeSwarm';
import ViolinChart from 'components/ViolinChart';
import Note from 'components/Note';
import XAxis from 'components/XAxis';
import Spacer from 'components/Spacer';
import CurvedArrow from 'components/Icons/CurvedArrow';
import objectToArray from 'helpers/objectToArray';
import useTooltip from 'helpers/useTooltip';
import useChartConfig from 'helpers/useChartConfig';

const formatTick = (tick) => `${tick}${tick === 0 || tick === 100 ? '%' : ''}`;

const CountriesRecentData = ({ id }) => {
	const { data, copy, config } = useChartConfig('countriesRecentData', {
		heightRatio: 0.5,
		dotRadius: 0.55,
		dotPadding: 0.1,
		min: 0,
		max: 100,
		xDataKey: 'recentData',
		yDataKey: 'incomeGroup',
		includeAxis: true,
		ticks: [0, 20, 40, 60, 80, 100],
		yAlignment: ['lowIncome', 'lowMiddle', 'upperMiddle', 'highIncome'],
		colors: {
			lowIncome: '#008dc9',
			lowMiddle: '#002D5F',
			upperMiddle: '#7236D6',
			highIncome: '#E7A113'
		}
	});

	const dataWithColors = useMemo(
		() =>
			objectToArray(data).map((d) => ({
				...d,
				fill: config.colors[d.incomeGroup]
			})),
		[]
	);

	const violinData = useMemo(
		() =>
			config.yAlignment.map((key) => ({
				id: key,
				color: config.colors[key],
				data: dataWithColors.filter((d) => d.incomeGroup === key)
			})),
		[dataWithColors]
	);

	const { ref, Tooltip, onMouseOver, onMouseOut } = useTooltip();
	return (
		<Chart id={id} copy={copy}>
			<div
				style={{
					position: 'relative',
					marginTop: '2rem'
				}}
				ref={ref}
			>
				<Note
					id="country"
					x={50.5}
					y={6}
					iconPosition="bl"
					icon={<CurvedArrow />}
				/>

				<Spacer size={0.06} />

				<div
					style={{
						position: 'absolute',
						width: '100%',
						top: 0,
						left: 0
					}}
				>
					{violinData.map(({ id, data, color }) => (
						<ViolinChart
							key={id}
							{...config}
							fill={color}
							heightRatio={0.125}
							data={data}
						/>
					))}
				</div>

				<BeeSwarm
					data={dataWithColors}
					copy={copy.swarms}
					{...config}
					dotColor={config.colors}
					onMouseOver={onMouseOver}
					onMouseOut={onMouseOut}
				/>

				<Spacer size={0.06} />

				<XAxis
					ticks={config.ticks}
					min={config.min}
					max={config.max}
					formatTick={formatTick}
					includeKeyLine
					isVisible
					insetEnds
				/>

				{Tooltip}
			</div>
		</Chart>
	);
};

export default CountriesRecentData;
