import React from 'react';
import s from './style.module.css';

const CurvedArrow = () => (
	<svg
		className={s.svg}
		width="24"
		height="24"
		viewBox="0 0 100 100"
		style={{ overflow: 'visible', transform: 'scaleX(-1)' }}
	>
		<path
			strokeWidth="4"
			d="M0,0 Q100,0 100,100"
			stroke="#000"
			fill="transparent"
		/>
		<path
			strokeWidth="4"
			d="M100,100 L120,80"
			stroke="#000"
			fill="transparent"
		/>
		<path
			strokeWidth="4"
			d="M100,100 L80,80"
			stroke="#000"
			fill="transparent"
		/>
	</svg>
);

export default CurvedArrow;
