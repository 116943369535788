import { useEffect, useRef, useState } from 'react';
import { forceSimulation } from 'd3-force';
// import useAnimationTimer from 'helpers/useAnimationTimer';
import { interval } from 'd3-timer';
import roundTo from 'round-to';

const useSimulation = (items, forces, throttleTime = 1) => {
	const nodes = useRef(items);
	const simulation = useRef();
	const [, setTick] = useState(0);

	useEffect(() => {
		// console.log('start sim');
		simulation.current = forceSimulation(nodes.current)
			.alphaDecay(0.001)
			.stop();

		return () => {
			simulation.current.stop();
		};
	}, []);

	useEffect(() => {
		forces.forEach(([name, force]) => {
			simulation.current.force(name, force);
		});
	}, [forces]);

	useEffect(() => {
		const loop = interval((elapsed) => {
			simulation.current.tick();

			setTick((tick) => tick + 1);
		}, 1000 / 30);

		return loop.stop;
	}, [items]);

	const rounded = nodes.current.map((node) => ({
		...node,
		x: roundTo(node.x || 0, 1),
		y: roundTo(node.y || 0, 1),
		vx: roundTo(node.vx || 0, 1),
		vy: roundTo(node.vy || 0, 1)
	}));

	return rounded;
};

export default useSimulation;
